.profile-menu-verified-icon-wrapper {
  bottom: 0.5px;
  right: 0px;
  background-color: white;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  position: absolute;
}

.profile-menu-verified-icon {
  color: #007bff;
  font-size: 18px;
  position: absolute;
  top: -7px;
}
